// extracted by mini-css-extract-plugin
export var application = "DigitalSovereigntyMain-module--application--37281";
export var applicationHeading = "DigitalSovereigntyMain-module--applicationHeading--63ca6";
export var applicationMap = "DigitalSovereigntyMain-module--applicationMap--d3ca3";
export var approach = "DigitalSovereigntyMain-module--approach--5f02c";
export var awsExpertImg = "DigitalSovereigntyMain-module--awsExpertImg--b26fd";
export var awsExpertImgDiv = "DigitalSovereigntyMain-module--awsExpertImgDiv--7313c";
export var awsMainImage = "DigitalSovereigntyMain-module--awsMainImage--7e9e5";
export var backgroundCircles = "DigitalSovereigntyMain-module--backgroundCircles--34e3f";
export var banner = "DigitalSovereigntyMain-module--banner--6993d";
export var benefits = "DigitalSovereigntyMain-module--benefits--3812b";
export var bookButton = "DigitalSovereigntyMain-module--bookButton--ba85f";
export var bottomButton = "DigitalSovereigntyMain-module--bottomButton--c8704";
export var careerBtnApplyNow = "DigitalSovereigntyMain-module--careerBtnApplyNow--624bd";
export var careers = "DigitalSovereigntyMain-module--careers--f31b2";
export var caseChallengeImage = "DigitalSovereigntyMain-module--caseChallengeImage--6a244";
export var caseTestimonialImage = "DigitalSovereigntyMain-module--caseTestimonialImage--bf264";
export var circleIcon = "DigitalSovereigntyMain-module--circleIcon--5fe0a";
export var complianceHeading = "DigitalSovereigntyMain-module--complianceHeading--057b6";
export var complianceItem = "DigitalSovereigntyMain-module--complianceItem--3d8eb";
export var complianceItems = "DigitalSovereigntyMain-module--complianceItems--036fd";
export var complianceSection = "DigitalSovereigntyMain-module--complianceSection--b0289";
export var contact = "DigitalSovereigntyMain-module--contact--3aa73";
export var contactButton = "DigitalSovereigntyMain-module--contactButton--7ee54";
export var contactOutlineCircleBenfits = "DigitalSovereigntyMain-module--contactOutlineCircleBenfits--047c0";
export var coreValue = "DigitalSovereigntyMain-module--coreValue--9d874";
export var coreValueColumbLeft = "DigitalSovereigntyMain-module--coreValueColumbLeft--0f495";
export var coreValueColumbRight = "DigitalSovereigntyMain-module--coreValueColumbRight--82e54";
export var coreValueItem = "DigitalSovereigntyMain-module--coreValueItem--89291";
export var coreValueItemBox = "DigitalSovereigntyMain-module--coreValueItemBox--4d27c";
export var customButton = "DigitalSovereigntyMain-module--customButton--40f9a";
export var digitalSovereigntySection = "DigitalSovereigntyMain-module--digitalSovereigntySection--0f455";
export var eventDetails = "DigitalSovereigntyMain-module--eventDetails--284c3";
export var flowContainer = "DigitalSovereigntyMain-module--flowContainer--c85bd";
export var flowItem = "DigitalSovereigntyMain-module--flowItem--64154";
export var flowItemDay = "DigitalSovereigntyMain-module--flowItemDay--e9156";
export var flowItemDayMobile = "DigitalSovereigntyMain-module--flowItemDayMobile--2fc2e";
export var flowItemDayMobileContainer = "DigitalSovereigntyMain-module--flowItemDayMobileContainer--1be6b";
export var flowItemMobile = "DigitalSovereigntyMain-module--flowItemMobile--e086c";
export var flowLeftContainer = "DigitalSovereigntyMain-module--flowLeftContainer--81762";
export var flowRightContainer = "DigitalSovereigntyMain-module--flowRightContainer--a40dd";
export var flowRightContainerMobile = "DigitalSovereigntyMain-module--flowRightContainerMobile--d3f90";
export var flowStop = "DigitalSovereigntyMain-module--flowStop--9dc7e";
export var followUs = "DigitalSovereigntyMain-module--followUs--6cec5";
export var gdnPromotePic = "DigitalSovereigntyMain-module--gdnPromotePic--ff3d7";
export var heading = "DigitalSovereigntyMain-module--heading--8ebe4";
export var headline = "DigitalSovereigntyMain-module--headline--e9364";
export var heroBackgroundCircleLeft = "DigitalSovereigntyMain-module--heroBackgroundCircleLeft--518f5";
export var heroBackgroundCircleRight = "DigitalSovereigntyMain-module--heroBackgroundCircleRight--2404c";
export var heroBackgroundInnerCircleGreen = "DigitalSovereigntyMain-module--heroBackgroundInnerCircleGreen--19571";
export var heroBackgroundInnerCircleLeft = "DigitalSovereigntyMain-module--heroBackgroundInnerCircleLeft--c6d1e";
export var heroBackgroundInnerCircleLeftBenfits = "DigitalSovereigntyMain-module--heroBackgroundInnerCircleLeftBenfits--c7c60";
export var heroBannerMainContainer = "DigitalSovereigntyMain-module--heroBannerMainContainer--d3a91";
export var heroWhiteBlurCirce = "DigitalSovereigntyMain-module--heroWhiteBlurCirce--ba927";
export var iconPlaceholder = "DigitalSovereigntyMain-module--iconPlaceholder--6e2f8";
export var igImageGroup1 = "DigitalSovereigntyMain-module--igImageGroup1--64e1b";
export var igImageGroup1Item = "DigitalSovereigntyMain-module--igImageGroup1Item--f73ce";
export var igImageGroup2 = "DigitalSovereigntyMain-module--igImageGroup2--95bed";
export var igImageGroup2Btm = "DigitalSovereigntyMain-module--igImageGroup2Btm--b04e2";
export var igImageGroup2BtmItem = "DigitalSovereigntyMain-module--igImageGroup2BtmItem--6e693";
export var igImageGroup2Item = "DigitalSovereigntyMain-module--igImageGroup2Item--bfb25";
export var inNumberColumbLeft = "DigitalSovereigntyMain-module--inNumberColumbLeft--ae120";
export var inNumberColumbRight = "DigitalSovereigntyMain-module--inNumberColumbRight--fd95b";
export var inNumbersImage = "DigitalSovereigntyMain-module--inNumbersImage--f4dc1";
export var inNumbersItem = "DigitalSovereigntyMain-module--inNumbersItem--0c8db";
export var innumbers = "DigitalSovereigntyMain-module--innumbers--2889a";
export var insights = "DigitalSovereigntyMain-module--insights--e340f";
export var instaContainer = "DigitalSovereigntyMain-module--instaContainer--3ff5e";
export var instaLeftBox = "DigitalSovereigntyMain-module--instaLeftBox--c3a58";
export var instaRightBox = "DigitalSovereigntyMain-module--instaRightBox--de0ce";
export var introDescriptionButtonSection = "DigitalSovereigntyMain-module--introDescriptionButtonSection--1c9da";
export var introImageSection = "DigitalSovereigntyMain-module--introImageSection--24e86";
export var introSectionCircleItem = "DigitalSovereigntyMain-module--introSectionCircleItem--c6e2b";
export var introSectionContainer = "DigitalSovereigntyMain-module--introSectionContainer--49617";
export var introSectionText = "DigitalSovereigntyMain-module--introSectionText--380e1";
export var keyTopicsImage = "DigitalSovereigntyMain-module--keyTopicsImage--dfa44";
export var knowUs = "DigitalSovereigntyMain-module--knowUs--402f9";
export var knowUsImage = "DigitalSovereigntyMain-module--knowUsImage--fb3bb";
export var knowUsSubDiv = "DigitalSovereigntyMain-module--knowUsSubDiv--b9247";
export var knowUsText = "DigitalSovereigntyMain-module--knowUsText--b38fe";
export var pillar = "DigitalSovereigntyMain-module--pillar--8ca2f";
export var pillarIcon = "DigitalSovereigntyMain-module--pillarIcon--b4036";
export var pillarText = "DigitalSovereigntyMain-module--pillarText--10ae1";
export var pillars = "DigitalSovereigntyMain-module--pillars--17173";
export var pillarsContainer = "DigitalSovereigntyMain-module--pillarsContainer--adb0f";
export var profileContainer = "DigitalSovereigntyMain-module--profileContainer--73c66";
export var profileContent = "DigitalSovereigntyMain-module--profileContent--87d7c";
export var profileDescription = "DigitalSovereigntyMain-module--profileDescription--7cd9d";
export var profileImage = "DigitalSovereigntyMain-module--profileImage--d1471";
export var profileName = "DigitalSovereigntyMain-module--profileName--e8bbd";
export var profileSection = "DigitalSovereigntyMain-module--profileSection--1439f";
export var reportBtn = "DigitalSovereigntyMain-module--reportBtn--4975a";
export var reportBtnGerman = "DigitalSovereigntyMain-module--reportBtnGerman--647a4";
export var roadshowContent = "DigitalSovereigntyMain-module--roadshowContent--eeaa1";
export var roadshowHeading = "DigitalSovereigntyMain-module--roadshowHeading--d1f30";
export var roadshowItem = "DigitalSovereigntyMain-module--roadshowItem--d9540";
export var roadshowListContainer = "DigitalSovereigntyMain-module--roadshowListContainer--6a591";
export var roadshowSection = "DigitalSovereigntyMain-module--roadshowSection--f6fb8";
export var roadshowTextContainer = "DigitalSovereigntyMain-module--roadshowTextContainer--98ce7";
export var singleBenefitTextBox = "DigitalSovereigntyMain-module--singleBenefitTextBox--edcd1";
export var singleCaseChallenge = "DigitalSovereigntyMain-module--singleCaseChallenge--d13bd";
export var singleCaseChallengeFirst = "DigitalSovereigntyMain-module--singleCaseChallengeFirst--e544e";
export var singleCaseImageBox = "DigitalSovereigntyMain-module--singleCaseImageBox--e6a10";
export var singleCaseImageBoxForth = "DigitalSovereigntyMain-module--singleCaseImageBoxForth--10fc2";
export var singleCaseImageBoxSecond = "DigitalSovereigntyMain-module--singleCaseImageBoxSecond--f6e9e";
export var singleCaseSolution = "DigitalSovereigntyMain-module--singleCaseSolution--90228";
export var singleCaseSolutionMobile = "DigitalSovereigntyMain-module--singleCaseSolutionMobile--f60b5";
export var singleCaseTestimonial = "DigitalSovereigntyMain-module--singleCaseTestimonial--46bdb";
export var singleCaseTestimonialInner = "DigitalSovereigntyMain-module--singleCaseTestimonialInner--5e654";
export var singleCaseTextBox = "DigitalSovereigntyMain-module--singleCaseTextBox--0d017";
export var singleCaseTextBoxLast = "DigitalSovereigntyMain-module--singleCaseTextBoxLast--8f91a";
export var testimonialBody = "DigitalSovereigntyMain-module--testimonialBody--93acf";
export var testimonialContent = "DigitalSovereigntyMain-module--testimonialContent--8b8cf";
export var testimonialName = "DigitalSovereigntyMain-module--testimonialName--e5a38";
export var testimonialSection = "DigitalSovereigntyMain-module--testimonialSection--5368f";
export var torchWrapper = "DigitalSovereigntyMain-module--torchWrapper--20f18";
export var verticalLine = "DigitalSovereigntyMain-module--verticalLine--f70ba";
export var verticalLineMobile = "DigitalSovereigntyMain-module--verticalLineMobile--25ede";
export var yourBenefits = "DigitalSovereigntyMain-module--yourBenefits--b7db4";
export var yourBenefitsIcon = "DigitalSovereigntyMain-module--yourBenefitsIcon--7f31a";
export var yourBenefitsInner = "DigitalSovereigntyMain-module--yourBenefitsInner--bd180";
export var yourBenefitsItem = "DigitalSovereigntyMain-module--yourBenefitsItem--a599b";