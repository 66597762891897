import React, { FC, useContext } from "react";
import * as styles from "./DigitalSovereigntyMain.module.scss";
import { UseWindowSize } from "../hooks/UseWindowSize";
import translate from "../../i18n/translate";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import CustomButton from "../Shared/CustomButton/CustomButton";
import arrowRightWhite from "../../images/arrowRightWhite.svg";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import stage from "./headerimage.jpg";
import { LanguageContext } from "../../context/languageContext";
import aiact from "./icons/aiact.png";
import c5 from "./icons/c5.png";
import gdpr from "./icons/gdpr.png";
import dora from "./icons/dora.png";
import nis2 from "./icons/nis2.png";
import fips from "./icons/fips.png";
import pci from "./icons/pci.png";
import dspOne from './icons/datalocation.png'
import dspTwo from "./icons/novendorlock.png"
import dspThree from "./icons/datasecurity.png"
import laura from "./pictures/laura.webp";
import awsMainImg from "./pictures/newyork.jpg";
import dspartner from "../../images/dspartner.png";
import { PopupButton } from "react-calendly";


const DigitalSovereigntyMain: FC = () => {
  const width = UseWindowSize();
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles.sassApplication}>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={translate("digital-sovereignty-header")}
          description={translate("digital-sovereignty-subtitle")}
          homeBackground={stage}
          buttonText={translate("sov-header-button")}
          date=""
          link="#learn-about-sovereignty"
          isExternalLink
          alt="learn-about-sovereignty"
        />
      ) : (
        <HeroBanner
          heading={translate("digital-sovereignty-header")}
          description={translate("digital-sovereignty-subtitle")}
          homeBackground={stage}
          buttonText={translate("sov-header-button")}
          date=""
          link="#learn-about-sovereignty"
          isExternalLink
        />
      )}
      <InnerWrapper>
 
        <section className={styles.digitalSovereigntySection}>
          <h2 className={styles.heading} id="learn-about-sovereignty">.{translate("digital-sovereignty-pilars")}</h2>
          <div className={styles.pillarsContainer}>
          <div className={styles.pillar}>
              <img src={dspTwo} alt="Technical Independence" className={styles.pillarIcon} />
              <p className={styles.pillarText}>
              <strong>{translate("digital-sovereignty-pilars-02")}</strong>
              </p>
              <CustomButton
                  buttonClass={styles.bottomButton}
                  text={translate("sov-tech-blog-button")}
                  linkPath={
                    language === "EN"
                      ? "../cloud-insights/technological-independence-in-the-aws-cloud"
                      : "../cloud-insights/technologische-unabhngigkeit-in-der-aws-cloud"
                  }
                  image={arrowRightWhite}
                />
            </div>
            <div className={styles.pillar}>
              <img src={dspOne} alt="Data Sovereignty" className={styles.pillarIcon} />
              <p className={styles.pillarText}>
              <strong>{translate("digital-sovereignty-pilars-01")}</strong>
              </p>
              <CustomButton
                  buttonClass={styles.bottomButton}
                  text={translate("sov-data-blog-button")}
                  linkPath={
                    language === "EN"
                      ? "../cloud-insights/confidence-in-the-cloud-with-data-sovereignty"
                      : "../cloud-insights/vertrauen-in-die-cloud-mit-datensouvernitt"
                  }
                  image={arrowRightWhite}
                />
            </div>
            <div className={styles.pillar}>
              <img src={dspThree} alt="Security" className={styles.pillarIcon} />
              <p className={styles.pillarText}>
              <strong>{translate("digital-sovereignty-pilars-03")}</strong>
              </p>
              <CustomButton
                    buttonClass={styles.bottomButton}
                    text={translate("sov-sec-blog-button")}
                    linkPath={
                      language === "EN"
                        ? "../cloud-insights/security-as-the-foundation-of-digital-sovereignty"
                        : "../cloud-insights/sicherheit-als-fundament-digitaler-souvernitt"
                    }
                    image={arrowRightWhite}
                  />
            </div>
          </div>
        </section>

        <section className={styles.complianceSection}>
          <h3 className={styles.complianceHeading}>.{translate("keep-your-data-secure-and-compliant")}</h3>
          <div className={styles.complianceItems}>

            {/* {["GDPR", "DORA", "AI ACT", "NIS2", "c5", "MFA","Data Encryption", "Zero Trust"].map((item, index) => (
              <div key={index} className={styles.complianceItem}>
                <img src={dspCheck} alt="check dsp" />
                <span style={{color:"#00ecbd"}}>{item}</span>
              </div>
            ))} */}
            <div key="fips" className={styles.complianceItem}>
                <img src={fips} alt="fips" />
              </div>
            <div key="gdpr" className={styles.complianceItem}>
                <img src={gdpr} alt="gdpr" />
              </div>
              <div key="aiact" className={styles.complianceItem}>
                <img src={aiact} alt="gdpr" />
              </div>
              <div key="c5" className={styles.complianceItem}>
                <img src={c5} alt="gdpr" />
              </div>
              <div key="nis2" className={styles.complianceItem}>
                <img src={nis2} alt="nis2" />
              </div>
              <div key="dora" className={styles.complianceItem}>
                <img src={dora} alt="dora" />
              </div>
              <div key="pci" className={styles.complianceItem}>
                <img src={pci} alt="pci" />
              </div>
          </div>
        </section>


        <section className={styles.testimonialSection}>
  <h3 className={styles.heading}>.Testimonial</h3>
  <div className={styles.singleCaseTestimonial}>
    <div className={styles.singleCaseTestimonialInner}>
      <img
        src={laura}
        alt="Laura Weißbach"
        className={styles.caseTestimonialImage}
      />
      <div className={styles.testimonialContent}>
        <h4>Laura Weißbach</h4>
        <p className={styles.testimonialName}>Data-Analyst -<b> NOW GmbH</b></p>
        <p className={styles.testimonialBody}>
          {translate("digital-sovereignty-testemonial")}
        </p>
        <CustomButton
            text={translate("sov-testimonial-button")}
            linkPath="../case-study/data-platform-for-germanys-e-charging-infrastructure"
            image={arrowRightWhite}
          />
        <CircleBlur
          initialScale={0.1}
          initialOpacity={0}
          animateScale={1.5}
          animateOpacity={0.15}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.heroBackgroundInnerCircleLeft}
        />
      </div>
    </div>
  </div>
</section>



      <div className={styles.knowUs}>
          <div className={styles.knowUsImage}>
            <div className={styles.knowUsSubDiv}>
              <img
                src={awsMainImg}
                alt="High tech city at sunrise to showcase AWS at forefront of digitalisation and cloud infrastructure"
                className={styles.awsMainImage}
              />
              <div className={styles.awsExpertImgDiv}>
                <img
                  src={dspartner}
                  alt="Badge for digital sovereignty - advanced sovereignty controls and features to allow customers around the world to meet their digital sovereignty requirements"
                  className={styles.awsExpertImg}
                />
              </div>
            </div>
          </div>
          <div className={styles.knowUsText}>
            <p>{translate('digital-sovereignty-gdn')}</p>
            <CircleOutline
              isBlurVisible={true}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundCircleLeft}
            />
            <CircleBlur
              initialScale={0.1}
              initialOpacity={0}
              animateScale={1.8}
              animateOpacity={0.15}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundInnerCircleLeft}
            />
          </div>
        </div>





<section className={styles.roadshowSection}>
  <div className={styles.roadshowContent}>
    {/* Left side with text */}
    <div className={styles.roadshowTextContainer}>
    <h2 className={styles.roadshowHeading}>.Roadshow</h2>
      <p>{translate("sov-roadshow-description")}</p>
      <p>{translate("sov-roadshow-description1")}</p>
    </div>
    
    {/* Right side with list items and background image */}
    <div className={styles.roadshowListContainer}>
        <div key="münchen" className={styles.roadshowItem}>
          <div className={styles.eventDetails}>
            <div className={styles.circleIcon}></div>
            <span>30.01.2025 - {translate("sov-city-loc1")}</span>
          </div>
          <CustomButton
            text={translate("sov-roadshow-button")}
            image={arrowRightWhite}
            externalLink="https://www.eventbrite.de/e/x1f-digital-sovereignty-roadshow-munchen-tickets-1098639392999"
          />
        </div>
        <div key="köln" className={styles.roadshowItem}>
          <div className={styles.eventDetails}>
            <div className={styles.circleIcon}></div>
            <span>13.02.2025 - {translate("sov-city-loc2")}</span>
          </div>
          <CustomButton
            text={translate("sov-roadshow-button")}
            image={arrowRightWhite}
            externalLink="https://www.eventbrite.de/e/x1f-digital-sovereignty-roadshow-koln-tickets-1104477605249"
          />
        </div>
        <div key="hamburg" className={styles.roadshowItem}>
          <div className={styles.eventDetails}>
            <div className={styles.circleIcon}></div>
            <span>27.02.2025 - {translate("sov-city-loc3")}</span>
          </div>
          <CustomButton
            text={translate("sov-roadshow-button")}
            image={arrowRightWhite}
            externalLink="https://www.eventbrite.de/e/x1f-digital-sovereignty-roadshow-hamburg-tickets-1099217512169"
          />
        </div>
    </div>
  </div>
</section>




        <section className={styles.profileSection}>
  <div className={styles.profileContainer}>
    <div style={{display:'flex',flexDirection:'column'}}>
    <div className={styles.profileImage} />
    
    </div>
    <div className={styles.profileContent}>
      <h5 className={styles.headline}>{translate("sov-headline-description1")}<span style={{ color: '#ffffff' }}> {translate("sov-headline-description2")}</span></h5>
      <h4 className={styles.profileName}>
        David Krohn - <span style={{ color: '#00ecbd' }}>Head of Security</span>
        <br/><br/>
        <a href="https://www.linkedin.com/in/daknhh/" target="blank">
      <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxNyAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuMDMwNSA1LjE4Njc3SDAuNDQyMTM5VjE1Ljk2MjdINC4wMzA1VjUuMTg2NzdaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMi4yNTc4NiAwQzEuNjU2MjUgMCAxLjE2ODc4IDAuMTc1OTIyIDAuNzk1MzM4IDAuNTI3NDI0QzAuNDIxOTM4IDAuODc5MDAyIDAuMjM1MzUyIDEuMzIyODYgMC4yMzUzNTIgMS44NTk0M0MwLjIzNTM1MiAyLjM4ODQ5IDAuNDE2NTY3IDIuODMwODMgMC43NzkwMzcgMy4xODU5NUMxLjE0MTM5IDMuNTQxMTEgMS42MTk5MSAzLjcxODc4IDIuMjE0MzMgMy43MTg3OEgyLjIzNjA4QzIuODQ1MDggMy43MTg3OCAzLjMzNjI4IDMuNTQxMjYgMy43MDk1NyAzLjE4NTk1QzQuMDgyODYgMi44MzA4MyA0LjI2NTg2IDIuMzg4NjQgNC4yNTg2NiAxLjg1OTQzQzQuMjUxNDMgMS4zMjI5IDQuMDY2NDQgMC44NzkwMDIgMy43MDQwOSAwLjUyNzQyNEMzLjM0MTc3IDAuMTc1NzcgMi44NTk1MiAwIDIuMjU3ODYgMFoiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xNS44MDQ5IDYuMTcxMDhDMTUuMDUxMSA1LjM0ODI2IDE0LjA1NDIgNC45MzY3NyAxMi44MTQ2IDQuOTM2NzdDMTIuMzU3OSA0LjkzNjc3IDExLjk0MjggNC45OTI5NSAxMS41Njk0IDUuMTA1MzhDMTEuMTk2MSA1LjIxNzczIDEwLjg4MDggNS4zNzUzNyAxMC42MjM0IDUuNTc4MzhDMTAuMzY2MSA1Ljc4MTM4IDEwLjE2MTIgNS45Njk4MyAxMC4wMDkxIDYuMTQzNzNDOS44NjQ0NiA2LjMwODkyIDkuNzIzMDEgNi41MDEzMyA5LjU4NDk4IDYuNzE5VjUuMTg2NjlINi4wMDczMkw2LjAxODI5IDUuNzA4NzFDNi4wMjU2NCA2LjA1Njc4IDYuMDI5MjYgNy4xMjk2MSA2LjAyOTI2IDguOTI3MzRDNi4wMjkyNiAxMC43MjUyIDYuMDIyMDMgMTMuMDcwMyA2LjAwNzQ4IDE1Ljk2MjhIOS41ODQ5OFY5Ljk0OTM5QzkuNTg0OTggOS41Nzk3NiA5LjYyNDYzIDkuMjg2MjMgOS43MDQ1MyA5LjA2ODU2QzkuODU2ODggOC42OTg2NiAxMC4wODY4IDguMzg4OTggMTAuMzk1MSA4LjEzODk4QzEwLjcwMzMgNy44ODg2OCAxMS4wODU2IDcuNzYzNjQgMTEuNTQyNCA3Ljc2MzY0QzEyLjE2NTcgNy43NjM2NCAxMi42MjQ0IDcuOTc5MjggMTIuOTE4IDguNDEwNjFDMTMuMjExNSA4Ljg0MTkxIDEzLjM1ODMgOS40MzgxOSAxMy4zNTgzIDEwLjE5OTRWMTUuOTYyNEgxNi45MzU4VjkuNzg2NDZDMTYuOTM1NiA4LjE5ODcgMTYuNTU4OSA2Ljk5MzY0IDE1LjgwNDkgNi4xNzEwOFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=" alt="follow globaldatanet on linkendin"/>
      </a>   </h4>
      {width.width < 1250 ? (
              <CustomButton
                text={
                  language === "EN"
                    ? translate("sov-connectbutton")
                    : translate("sov-connectbutton")
                }
                image={arrowRightWhite}
                externalLink="https://calendly.com/globaldatanet/digital-sovereignty"
                buttonClass={styles.customButton}
              />
            ) : typeof window !== "undefined" ? (
              <PopupButton
                url="https://calendly.com/globaldatanet/digital-sovereignty"
                rootElement={document.getElementById("___gatsby")}
                className={styles.customButton}
                pageSettings={{ 
                  backgroundColor: "111111",
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: false,
                  primaryColor: "00ecbd",
                  textColor: "ffffff",
                }}
                text={
                  language === "EN"
                    ? "Let’s Connect"
                    : "Lerne mich kennen"
                }
              />
            ) : (
              <CustomButton
                text={
                  language === "EN"
                    ? translate("sov-connectbutton")
                    : translate("sov-connectbutton")
                }
                image={arrowRightWhite}
                externalLink="https://calendly.com/globaldatanet/digital-sovereignty"
                buttonClass={styles.customButton}
              />
            )}
    </div>
  </div>
</section>
      </InnerWrapper>
    </div>
  );
};

export default DigitalSovereigntyMain;
