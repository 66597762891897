import React, { FC } from "react";
import SaasApplicationMain from "../components/SaasApplicationUiComponents/SaasApplicationMain";
import Layout from "../components/Layout/Layout";
import { SEO } from "../components/seo";
import { graphql } from "gatsby";
import sassStage from "../components/DigitalSovereigntyUiComponents/headerimage.jpg";
import DigitalSovereigntyMain from "../components/DigitalSovereigntyUiComponents/DigitalSovereigntyMain";

type Props = {
  data: any;
};

const DigitalSovereignty: FC<Props> = ({ data }) => {
  return (
    <Layout location="/blog">
      <SEO
        title="Digital Sovereignty"
        description="Take Control of Your Data with Trusted Cloud Solutions"
        type="website"
        image={sassStage}
      />
      {/* <SaasApplicationMain data={data} /> */}
      <DigitalSovereigntyMain />
    </Layout>
  );
};

export default DigitalSovereignty;

